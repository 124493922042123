import { useState } from "react";
import {
  ReportModel,
} from "../../models/ReportModel/report.model";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { AvailablePeriods } from "../../models/ReportModel/availablePeriods.model";

const ReportService = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [availablePeriods, setAvailablePeriods] =
    useState<AvailablePeriods[]>();

  const createReport = async (reportData: ReportModel) => {
    try {
      setLoading(true);
      const params = serialize(ReportModel, reportData);
      const response = await axiosInstance.get(ApiRoutes.TRADING_REPORT, {
        params,
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      window.open(url, "_blank");

      const a = document.createElement("a");
      a.href = url;
      a.download = "trading_report.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      window.URL.revokeObjectURL(url);
      return response.data;
    } catch (error) {
      return null
    } finally {
      setLoading(false);
    }
  };

  const getAvailablePeriods = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(ApiRoutes.AVAILABLE_PERIOD);
      const data = deserialize(
        AvailablePeriods,
        response.data["data"]
      ) as AvailablePeriods[];
      setAvailablePeriods(data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return {
    createReport,
    loading,
    getAvailablePeriods,
    availablePeriods,
  };
};

export default ReportService;
