export const ApiRoutes = {
  BASE_URL: process.env.REACT_APP_API_BASE_URL,
  // BASE_URL: process.env.REACT_APP_API_STUB_BASE_URL,
  USER_LOGIN: "/users/login",
  USER_LOGOUT: "/users/logout",
  GET_USER: "/users/me",
  GET_USER_WALLET: "/users/my-wallet",
  FORGOT_PASSWORD: "/forgot-password",
  GET_USER_WALLET_HISTORY: "/users/wallet/history",
  EMERGENCY_CONTACT: "users/emergency-contact",
  BENEFICIARY_DETAILS: "users/beneficiary-details",
  CREATE_ACCOUNT: "/users/signup",
  OTP_VERIFICATION: "/users/verify",
  RESEND_OTP: "/resend-otp",
  CASES: "/cases",
  DICOM_DATA: "/scan_center/dicom_data",
  AUTO_RENEWAL: "/subscription/auto-renewal",
  KYC_STEP: "/kyc/step",
  USER_ROLE_INFO: "/kyc/basic-info",
  USER_ROLE_INFO_UPDATE: "/kyc/particular-basic-info",
  USER_ROLE_INFO_NAME: "/kyc/basic-info/name",
  ID_PROOF_UPLOAD: "/kyc/id-proof",
  VIDEO_PROOF_UPLOAD: "/kyc/video-proof",
  VIDEO_UPLOAD_LINK: "/kyc/video-proof/generate-upload-link",
  VIDEO_PROOF_CODE: "/kyc/video-proof/verification-code",
  RISK_ASSESMENT_QUESTIONS: "/kyc/risk-assesment/questions",
  RISK_ASSESMENT: "/kyc/risk-assesment",
  KYC_BANK_DETAILS: "/kyc/bank-details",
  SUBSCRIPTION: "/subscription",
  SUBSCRIPTIONS: "/subscription/plans",
  SUBSCRIPTIONS_AMOUNT: "/subscription/:id",

  SHARES: "/shares/order",
  SHARES_HISTORY: "/shares/order/history",
  SHARES_OPTION: "/shares/order/option",
  SHARES_SELL: "/shares/sell-order",
  SHARES_BREAKDOWN: "/shares/order/option/breakdown",
  SHARES_SELL_OPTION: "/shares/sell-order/option",
  SHARES_SELL_HISTORY: "/shares/sell-order/history",
  SHARES_SELL_BREAKDOWN: "/shares/sell-order/option/breakdown",
  PAYMENT: "/payment/mpesa-request",
  WITHDRAW: "/withdraw_requests",
  CREATE_WITHDRAW_OTP: "/withdraw_requests/send-otp",
  CREATE_WITHDRAW: "/withdraw_requests/create",
  VERIFY_WITHDRAW_OTP: "/withdraw_requests/verify",
  INVITE: "/users/invite",
  FAQ: "/information/faq",
  TERMS_AND_CONDITIONS: "/information/general/terms_and_conditions",
  CURRENT_VALUATION: `/users/me/current-valuation`,
  SUPPORT_CONTACTS: "/users/me/support-contacts",

  META_RELATION: "meta/relations",
  COUNTRIES: "/users/countries-list",
  AUTO_DIVIDEND_OTP: "/users/me/send-otp",
  AUTO_DIVIDEND_INVEST: "/users/me/invest-option",
  APP_NOTIFICATIONS: "/users/notifications",
  MARK_ALL_AS_READ: "/users/notifications/mark-all-read",
  MARK_READ: "/users/notifications/mark-read",
  CONFIRM_ORDER: "/admin/orders/confirm-order",

  TRADING_REPORT: "/users/me/reports/trading-report",
  AVAILABLE_PERIOD: "/users/me/reports/trading-report/available-periods"
};
