import * as Yup from "yup";
import { ReportTypes } from "../../../enums/reportType.enum";

export const ReportValidationSchema = Yup.object().shape({
  reportType: Yup.string().required("Report Type is required!"),
  year: Yup.string()
    .test(
      "conditional-year-validation",
      "Year is required!",
      function (value) {
        const { reportType } = this.parent;

        if (reportType === ReportTypes.TRADING_REPORT) {
          return value != null && value.trim() !== "";
        }

        return true;
      }
    ),
});
