import { Col, Form, Modal, Row } from "antd";
import { Formik, FormikHelpers, FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import {
  BankDetail,
  BeneficiaryDetail,
} from "../../../../models/Customer/customer.model";
import DropdownField from "../../../../shared/components/DropdownField";
import InputField from "../../../../shared/components/InputField";
import RadioField from "../../../../shared/components/RadioField";
import UIModal from "../../../../shared/components/UIModal";
import { beneficiaryDetailsValidation } from "./validation";
import MetaService from "../../../../services/MetaService/meta.service";
import { BeneficiaryFormConstants } from "./constants";
import { INPUT_TYPE } from "../../../../enums/inputType";

interface BeneficiaryFormProps {
  visible: boolean;
  closeHandler: () => void;
  loading: boolean;
  submitHandler: (
    values: BeneficiaryDetail,
    formikHelpers: FormikHelpers<BeneficiaryDetail>
  ) => void;
}

const proofTypeOptions = [
  { label: "Kenyan National ID", value: false },
  { label: "Alien Card ID", value: true },
];

const BeneficiaryForm = (props: BeneficiaryFormProps) => {
  const { visible, closeHandler, submitHandler, loading } = props;

  const [formData, setFormData] = useState<BeneficiaryDetail>(
    new BeneficiaryDetail()
  );

  const { getRelationMetaData, relationMetaData } = MetaService();

  useEffect(() => {
    getRelationMetaData();
  }, []);

  const FROM_TITLE = "Add Next of Kin Details"

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      onSubmit={submitHandler}
      validationSchema={beneficiaryDetailsValidation}
    >
      {({
        setFieldValue,
        values,
        errors,
        dirty,
        resetForm,
        isValid,
        setFieldTouched,
        submitForm,
      }) => {
        return (
          <UIModal
            visible={visible}
            okText="Submit"
            loading={loading}
            onOk={submitForm}
            onClose={() => {
              resetForm();
              closeHandler();
            }}
            title={FROM_TITLE}
          >
            <Form>
              <div className="beneficiary-form__container">
                <Row gutter={[0, 15]}>
                  <Col sm={24} className="beneficiary__field">
                    <InputField
                      title={BeneficiaryFormConstants.FIELD_TITLES.NAME}
                      placeholder={"Enter"}
                      name="name"
                      type={INPUT_TYPE.TEXT}
                    />
                  </Col>
                  <Col sm={24} className="beneficiary__field">
                    <DropdownField
                      title={BeneficiaryFormConstants.FIELD_TITLES.RELATION}
                      optionLabelProp="label"
                      placeHolder={"  Select"}
                      name="relation"
                      options={relationMetaData.map((relation) => ({
                        label: relation.name,
                        value: relation.id,
                      }))}
                      showSearch={false}
                      value={values?.relation}
                      onChange={(value, option) => {
                        setFieldValue("relation", option.label);
                      }}
                    />
                  </Col>
                  <Col sm={24} className="beneficiary__field">
                    <InputField
                      title={
                        BeneficiaryFormConstants.FIELD_TITLES.DOCUMENTATION
                      }
                      placeholder={"Enter"}
                      name="kenyanNationalNumber"
                      type={INPUT_TYPE.TEXT}
                    />
                  </Col>
                  <Col sm={24} className="beneficiary__field">
                    <InputField
                      title={BeneficiaryFormConstants.FIELD_TITLES.PHONE}
                      placeholder={"Enter"}
                      name="phone"
                      type={INPUT_TYPE.TEXT}
                    />
                  </Col>
                </Row>
              </div>
            </Form>
          </UIModal>
        );
      }}
    </Formik>
  );
};

export default BeneficiaryForm;
