export const BeneficiaryFormConstants = {
  FIELD_TITLES: {
    NAME: "Next of Kin Name",
    RELATION: "Relation",
    DOCUMENTATION:
      "Next of Kin Documentation (ID, Passport, Alien ID, Certificate of Incorporation)",
    PHONE: "Next of Kin Phone Number",
  },
  VALIDATION_MESSAGES: {
    NAME_REQUIRED: "Next of Kin Name is required!",
    RELATION_REQUIRED: "Next of Kin Relation is required!",
    PHONE: {
      NUMERIC:
        "Next of Kin Phone number should be numeric and should not start with 0",
      MIN_LENGTH: "Next of Kin Phone number must be at least 9 characters",
      MAX_LENGTH:
        "Next of Kin Phone number must have a maximum of 10 characters",
      REQUIRED: "Next of Kin Phone number is required!",
    },
  },
};
