import { primitive, serializable, alias, list } from "serializr";
import { ReportTypes } from "../../enums/reportType.enum";

export class ReportModel {
  @serializable(alias("report_type", primitive()))
  reportType?: ReportTypes;

  @serializable(alias("start_date", primitive()))
  startDate?: string;

  @serializable(alias("end_date", primitive()))
  endDate?: string;

  @serializable
  year?: string;
}

