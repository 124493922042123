import { Row, Col, Button, Switch, Divider } from "antd";
import React, { useState, useEffect } from "react";
import BankIcon from "./../../../assets/icons/multi color icons - svg/bank details.svg";
import EmergencyIcon from "./../../../assets/icons/multi color icons - svg/emergency contact.svg";
import BeneficiaryIcon from "./../../../assets/icons/multi color icons - svg/beneficary.svg";
import PageHeader from "../../../shared/components/PageHeader";
import ProfileBasicDetailsCard from "../../../shared/components/ProfileBasicDetailsCard";
import ProfileOtherDetailsCard from "../../../shared/components/ProfileOtherDetailsCard";
import { AuthState } from "../../../store/reducers/authReducer";
import {
  BankDetail,
  BeneficiaryDetail,
  Customer,
  EmergencyContact,
} from "../../../models/Customer/customer.model";
import AuthContainer from "../../../store/container/AuthContainer";
import "./profile.scss";
import { useHistory } from "react-router";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import BankForm from "./BankForm";
import BeneficiaryForm from "./BeneficiaryForm";
import EmergencyForm from "./EmergencyForm";
import { FormikHelpers, FormikValues } from "formik";
import KYCService from "../../../services/KYCService/kyc.service";
import Loader from "../../../shared/components/Loader";
import AuthService from "../../../services/AuthService/auth.service";
import SubscriptionService from "../../../services/SubscriptionService/subscription.service";
import { User } from "../../../models/User/user.model";

interface ProfileProps extends AuthState { }

interface CardContent {
  title: string;
  icon: string;
  iconBgColor: string;
  contents: {
    label: string;
    value?: string | number;
  }[];
}
const Profile = (props: ProfileProps) => {
  const history = useHistory();
  const { user } = props;
  const [autoRenewalEnabled, setAutoRenewalEnabled] = useState(user?.subscriptionAutoRenewalEnabled === 1);
  const [renewalDisabled, setRenewalDisabled] = useState(false);
  const { setAutoRenewal } = SubscriptionService();

  const {
    bankDetails: rawBankDetails,
    updateKycBankDetails,
    loading } = KYCService();

  const {
    loading: authServiceLoading,
    updateEmergencyContact,
    updateBeneficiaryDetails,
    getUserDetails,
    user: profileData,
    fetchBeneficiaryDetails,
    fetchEmergencyContact,
    beneficiaryDetails: rawBeneficiaryDetails,
    emergencyContact: rawEmergencyContact,
  } = AuthService();

  const customer = new Customer();
  const [isBankFormVisible, setIsBankFormVisibile] = useState<boolean>(false);
  const [isBeneficiaryFormVisible, setIsBeneficiaryFormVisibile] =
    useState<boolean>(false);
  const [isEmergencyFormVisible, setIsEmergencyFormVisibile] =
    useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<
    BankDetail | BeneficiaryDetail | EmergencyContact
  >();
  const [beneficiaryDetails, setBeneficiaryDetails] = useState<CardContent>();
  const [emergencyDetails, setEmergencyDetails] = useState<CardContent>();
  const [bankDetails, setBankDetails] = useState<CardContent>();
  
  useEffect(() => {
    getUserDetails();
    fetchBeneficiaryDetails()
    fetchEmergencyContact()
  }, [])

  useEffect(() => {

      const formattedBeneficiaryDetails = formatBeneficiaryDetails(rawBeneficiaryDetails);
      setBeneficiaryDetails(formattedBeneficiaryDetails);

      const formattedEmergencyDetails = formatEmergencyDetails(rawEmergencyContact);
      setEmergencyDetails(formattedEmergencyDetails);

      const formattedBankDetails = formatBankDetails(rawBankDetails, user);
      setBankDetails(formattedBankDetails);

  }, [user, rawEmergencyContact, rawBeneficiaryDetails]);

  const formatBeneficiaryDetails = (rawBeneficiaryDetails?:BeneficiaryDetail) => ({
    title: "Next of Kin",
    icon: BeneficiaryIcon,
    iconBgColor: "mid-dark-purple",
    contents: [
      {
        label: "Name",
        value: rawBeneficiaryDetails?.name,
      },
      {
        label: "Next of Kin Documentation ID",
        value: rawBeneficiaryDetails?.kenyanNationalNumber,
      },
      {
        label: "Phone Number",
        value: rawBeneficiaryDetails?.phone,
      },
      {
        label: "Relation",
        value: rawBeneficiaryDetails?.relation,
      },
    ],
  });

  const formatEmergencyDetails = (rawEmergencyContact?:EmergencyContact) => ({
    title: "Emergency Contact",
    icon: EmergencyIcon,
    iconBgColor: "mid-dark-green",
    contents: [
      {
        label: "Name",
        value: rawEmergencyContact?.name,
      },
      {
        label: "Phone Number",
        value: rawEmergencyContact?.phone,
      },
    ],
  });

  const formatBankDetails = (rawBankDetails?:BankDetail, user?:User) => (
    {
      title: "Bank Details",
      icon: BankIcon,
      iconBgColor: "mid-dark-yellow",
      
      contents: user?.kycBankDetails?.bankAccountType === "International Bank Account" ?
      [
        {
          label:"Account name",
          value: rawBankDetails?.accountHolderName || user?.kycBankDetails?.accountHolderName,
        },
        {
          label: "Account number/Iban",
          value: rawBankDetails?.accountNumber || user?.kycBankDetails?.accountNumber,
        },
        {
          label: "Account type",
          value: rawBankDetails?.bankAccountType || user?.kycBankDetails?.bankAccountType,
        },
        {
          label: "Bank name",
          value: rawBankDetails?.bankName || user?.kycBankDetails?.bankName,
        },
        {
          label: "Branch",
          value: rawBankDetails?.branchName || user?.kycBankDetails?.branchName,
        },
        
        {
          label: "Swift code",
          value: rawBankDetails?.swiftCode || user?.kycBankDetails?.swiftCode,
        },
        {
          label: "Country",
          value: rawBankDetails?.country?.name || user?.kycBankDetails?.country?.name,

        },
        {
          label: "Account Currency",
          value: rawBankDetails?.currency || user?.kycBankDetails?.currency
        }
      ]:
       [
        {
          label:"Account name",
          value: rawBankDetails?.accountHolderName || user?.kycBankDetails?.accountHolderName,
        },
        {
          label: "Account number/Iban",
          value: rawBankDetails?.accountNumber || user?.kycBankDetails?.accountNumber,
        },
        {
          label: "Account type",
          value: rawBankDetails?.bankAccountType || user?.kycBankDetails?.bankAccountType,
        },
        {
          label: "Bank name",
          value: rawBankDetails?.bankName || user?.kycBankDetails?.bankName,
        },
        {
          label: "Branch",
          value: rawBankDetails?.branchName || user?.kycBankDetails?.branchName,
        },
        
        {
          label: "Swift code",
          value: rawBankDetails?.swiftCode || user?.kycBankDetails?.swiftCode,
        },
      ]
    }
  )

  const bankDetailsSubmitHandler = async (
    values: BankDetail,
    helpers: FormikHelpers<BankDetail>
  ) => {
    try {
      await updateKycBankDetails(values);
      getUserDetails();
      setIsBankFormVisibile(false);
      setSelectedData(undefined);
      helpers.resetForm();
    } catch (error) { }
  };
  const beneficiaryDetailsSubmitHandler = async (
    values: BeneficiaryDetail,
    helpers: FormikHelpers<BeneficiaryDetail>
  ) => {
    try {
      await updateBeneficiaryDetails(values);
      setIsBeneficiaryFormVisibile(false);
      setSelectedData(undefined);
      helpers.resetForm();
    } catch (error) { }
  };
  const emergencyDetailsSubmitHandler = async (
    values: EmergencyContact,
    helpers: FormikHelpers<EmergencyContact>
  ) => {
    try {
      await updateEmergencyContact(values);
      getUserDetails();
      setIsEmergencyFormVisibile(false);
      setSelectedData(undefined);
      helpers.resetForm();
    } catch (error) { }
  };
  const bankDetailsCloseHandler = () => {
    setIsBankFormVisibile(false);
  };
  const beneficiaryDetailsCloseHandler = () => {
    setIsBeneficiaryFormVisibile(false);
  };
  const emergencyDetailsCloseHandler = () => {
    setIsEmergencyFormVisibile(false);
  };
  const bankDetailsOpenHandler = (visibile: boolean, editMode?: boolean) => {
    setIsBankFormVisibile(true);
    if (editMode) {
      setSelectedData(user?.kycBankDetails);
    }
  };
  const beneficiaryDetailsOpenHandler = (
    visibile: boolean,
    editMode?: boolean
  ) => {
    setIsBeneficiaryFormVisibile(true);
    if (editMode) {
      // setSelectedData(editMode)
    }
  };



  const changeAutoRenewal = async (value: any) => {
    setRenewalDisabled(true);
    const res = await setAutoRenewal(value);
    if (res === 200) {
      setAutoRenewalEnabled(value);

    } else {
      setAutoRenewalEnabled(!value);
    }
    setRenewalDisabled(false);
  }
  const emergencyDetailsOpenHandler = (
    visibile: boolean,
    editMode?: boolean
  ) => {
    setIsEmergencyFormVisibile(true);
    if (editMode) {
      // setSelectedData(editMode)
    }
  };
  

  return user === undefined ? (
    <Loader />
  ) : (
    <div className="customer-profile__container">
      <div className="customer-profile__header">
        <PageHeader title="My Profile"/>
      </div>
      <div className="customer-profile__body">
        <Row className="customer-profile__body-basic-details">
          <Col sm={24} md={24} lg={12} xs={24}>
            <ProfileBasicDetailsCard
              user={user || {}}
              customer={customer || {}}
            />
          </Col>
          <Col sm={24} md={24} lg={12} xs={24}>
            <div className="subscription-details">

              <Row style={{ width: '100%' }}>
                <Col span={24}>
                  <Row>
                    <Col span={18}>
                      <p className="subscription">
                        {!user?.userHasActivePlan
                          ? "Choose a plan"
                          : user?.subscription?.name + " Subscription"}
                      </p>
                    </Col>
                    <Col span={4}>
                      <Button
                        className="subscription-upgrade primary"
                        onClick={() => history.push(AppRoutes.SUBSCRIPTIONS)}
                      >
                        {user?.userHasActivePlan ? "Upgrade" : "Choose"}
                      </Button>
                    </Col>
                  </Row>


                </Col>
                {user.userHasActivePlan&&(
                <Col span={24}>
                  <Row>
                    <Col span={18}>

                      <p className="subscription"> Auto Renew</p>
                    </Col>

                    <Col span={4}>
                      <Switch defaultChecked={autoRenewalEnabled} disabled={renewalDisabled} onChange={changeAutoRenewal} />
                    </Col>
                  </Row>
                </Col>)}
              </Row>




            </div>
            {/* <div className="subscription-details">
              <p className="subscription">

                Choose a plan

              </p>
              <Button
                className="subscription-upgrade primary"
                onClick={() => history.push(AppRoutes.SUBSCRIPTIONS)}
              >
                {user?.subscription ? "Choose " : "Choose"}
              </Button>
            </div> */}

            <ProfileOtherDetailsCard
              data={bankDetails}
              setVisible={bankDetailsOpenHandler}
            />
            <ProfileOtherDetailsCard
              setVisible={beneficiaryDetailsOpenHandler}
              data={beneficiaryDetails}
            />
            <ProfileOtherDetailsCard
              data={emergencyDetails}
              setVisible={emergencyDetailsOpenHandler}
            />
          </Col>
        </Row>
      </div>
      <BankForm
        visible={isBankFormVisible}
        loading={loading}
        data={selectedData}
        closeHandler={bankDetailsCloseHandler}
        submitHandler={bankDetailsSubmitHandler}
      />
      <BeneficiaryForm
        visible={isBeneficiaryFormVisible}
        loading={authServiceLoading}
        closeHandler={beneficiaryDetailsCloseHandler}
        submitHandler={beneficiaryDetailsSubmitHandler}
      />
      <EmergencyForm
        visible={isEmergencyFormVisible}
        loading={authServiceLoading}
        closeHandler={emergencyDetailsCloseHandler}
        submitHandler={emergencyDetailsSubmitHandler}
      />
    </div>
  );
};

export default AuthContainer(Profile);
