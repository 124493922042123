import * as yup from "yup";
import { REGEX } from "../../../../shared/utils/regex";
import { BeneficiaryFormConstants } from "./constants";

export const beneficiaryDetailsValidation = yup.object().shape({
  name: yup
    .string()
    .required(BeneficiaryFormConstants.VALIDATION_MESSAGES.NAME_REQUIRED),
  relation: yup
    .string()
    .required(BeneficiaryFormConstants.VALIDATION_MESSAGES.RELATION_REQUIRED),
  phone: yup
    .string()
    .matches(
      REGEX.NUMERIC,
      BeneficiaryFormConstants.VALIDATION_MESSAGES.PHONE.NUMERIC
    )
    .min(9, BeneficiaryFormConstants.VALIDATION_MESSAGES.PHONE.MIN_LENGTH)
    .max(10, BeneficiaryFormConstants.VALIDATION_MESSAGES.PHONE.MAX_LENGTH)
    .required(BeneficiaryFormConstants.VALIDATION_MESSAGES.PHONE.REQUIRED),
});
