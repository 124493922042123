import { alias, primitive, serializable } from "serializr";

export class AvailablePeriods {
  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("start_date", primitive()))
  startDate?: string;

  @serializable(alias("end_date", primitive()))
  endDate?: string;
}
